// ------------------------------------------
// ------------- VARIABLES ------------------
// ------------------------------------------

$colors: (
  amarillo:          #ffd600,
  amarillo-claro:    #f1e2a6,
  verde-ilusion:     #007B22,
  verde-claro:       #eff7f2,
  gris-oscuro:       #303030,
  gris-oscuro3:      #333,
  gris-claro:        #CCC,
);

@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}

@mixin link-haf () {
     &:hover,
     &:active,
     &:focus { @content }
}

// Implementamos un nuevo hiddenStructure
%element-invisible {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
%element-oculto {display:none;visibility:hidden;}
// --------- ATENCION: ---------------------
// NO USAR tildes ni caracteres extranos en los comentarios
// Esto aniade una linea de chartset al inicio de la css compilada que
// genera un error

// ------------------------------------------
// ----------- importaciones ----------------
// ------------------------------------------

@import 'variables';


/* ---------------- Estilos tienda ONLINE CIDAT ----------------- */

.tienda-cidat .formControlsRight {text-align:right;}
.tienda-cidat div.criteriosBusqOrden #filtros {width:auto;display:block;}

#content .tienda-cidat div.detalle-articulo-tienda-cidat ul {margin-left:0;}
#content .tienda-cidat div.detalle-articulo-tienda-cidat select {width: 4.3em; display: inline-block;}
#content .tienda-cidat div.listado-productos-cidat ul {margin:0; padding: 0;}

.tienda-cidat .ficha-producto-cidat,
#content .tienda-cidat .ficha-producto-cidat {
	margin:1em 0;
	background-image: none;
	background-color: map-get($colors, verde-claro);
	padding:1em;
	display:flex;
}

.tienda-cidat div.imagen-cidat {width:16em;}
.tienda-cidat div.imagen-cidat img{display:block;max-width:100%;max-height:20em;margin:0 auto;}
.tienda-cidat div.ficha-cidat-right {
	padding-left: 1em;

	h3 {font-size: 100%;}
	select {width: 4.3em; display: inline-block;}
}

.mi-carrito-cidat input[type=text],
.detalle-articulo-tienda-cidat div input[type=text],
.listado-productos-cidat .ficha-producto-cidat div input[type=text] {width:3em;margin-bottom:1em;}


.tienda-cidat div.acciones {background-color:#e4e3e1;padding:0.7em 1em;display:inline-block;}
.tienda-cidat div.acciones input {width:2em;}

.tienda-cidat .mi-carrito-cidat {font-size: 80%;}
.tienda-cidat .mi-carrito-cidat table {border:1px solid #333;width:100%;margin-bottom:0 !important;}
.tienda-cidat .mi-carrito-cidat table + table {line-height: 1.5em;}
.totalWidth {width: 11%;}

.tienda-cidat .mi-carrito-cidat .fila_cabecera {border-bottom:1px solid #333;}
.tienda-cidat .mi-carrito-cidat .fila_total {border-top:1px solid #333;background-color:#303030;color:white;}
.tienda-cidat .mi-carrito-cidat .fila_gastos {background-color:#303030;color:white;}

.tienda-cidat .mi-carrito-cidat .tienda-cidat-tabla-derecha {
	text-align:right;

	select {width: 4.3em; display: inline-block;}
}
.tienda-cidat .mi-carrito-cidat .tienda-cidat-tabla-centrado {text-align:center;}

.address_line {

	margin-bottom: 2em;

	.field {margin-bottom: 0;}
	.fieldInline {display:inline-block;margin-right:2em;}
}

#tienda-actions {
	padding: 1.8em 0;

	@media screen and (max-width : 1200px) {padding:1.8em 1.1rem;}

	ul {padding: 0;}
	li {display:inline-block;vertical-align:middle;word-wrap:break-word;margin:0 -1px 0 -1px;}
	a {
		padding: 9px 14px;
		font-weight: bold;
		display: block;
		
		@include link-haf {background-color:map-get($colors, verde-ilusion);color:white;}

		.badge {
			background-color: map-get($colors, verde-ilusion);
    		color: white;
    		border: 2px solid white;
    		border-radius: 1em;
    		padding: 0.3em 0.65em;
    		margin-top: -6px;
    		font-size: 80%;
    	}
	}
}

#form-widgets-tipoVia,
#form-widgets-tipoViaEnvio,
#form-widgets-nomVia,
#form-widgets-nomViaEnvio,
#form-widgets-provincia,
#form-widgets-provinciaEvio,
#form-widgets-poblacion,
#form-widgets-poblacionEnvio,
#form-widgets-pais,
#form-widgets-paisEnvio,
#form-widgets-email,
#form-widgets-emailEnvio,
.formulario25em {
    width: 25em;
    max-width: 100%;

    @media (max-width: 767px) {width: 100%;}
}

#form-widgets-numVia,
#form-widgets-numViaEnvio,
#form-widgets-portal,
#form-widgets-portalEnvio,
#form-widgets-escalera,
#form-widgets-escaleraEnvio,
#form-widgets-piso,
#form-widgets-pisoEnvio,
#form-widgets-codPostal,
#form-widgets-codPostalEnvio,
#form-widgets-telefonoFijo,
#form-widgets-telefonoFijoEnvio,
#form-widgets-telefonoMovil,
#form-widgets-telefonoMovilEnvio {
    @extend .formulario25em;
    width: 12em;
}
#form-buttons-modificar {background-color: #007B22 !important;}

.change_main_address,
.change_envio_address {
	.once-left-search.input-group {margin: 2rem 0 0.1rem 0;}
	.once-left-search .input-group-text {margin-left: -0.3em !important;}
	#provincia-search,
	#poblacion-search,
	#pais-search {
		flex: none;
		width: 25.5em;

		@media (max-width: 767px) {flex:1 1 auto; width: auto;}
	}
}


/* -------------------------------------- */
/* ------------ Media Queries ----------- */
/* -------------------------------------- */

@media screen and (max-width : 767px) {
	.tienda-cidat .ficha-producto-cidat,
	#content .tienda-cidat .ficha-producto-cidat {flex-wrap:wrap;padding:1em;}

	.tienda-cidat div.imagen-cidat {
		margin:0 0 1em 0;
		width: 100%;

		img {width: 100%; max-height: none;}
	}
	.tienda-cidat div.ficha-cidat-right {padding: 0;}

	#content select#tipoCat {max-width:100%;}

	.listado-productos-cidat .ficha-producto-cidat div input[type=text] {margin-bottom:0.5em;}

	.fieldInline {display:block;margin-bottom:0.5em;}

	.tienda-cidat .mi-carrito-cidat {font-size: inherit;}
	.tienda-cidat .mi-carrito-cidat .tienda-cidat-tabla-centrado,
	.tienda-cidat .mi-carrito-cidat .tienda-cidat-tabla-derecha {text-align:left;}

	.tienda-cidat .mi-carrito-cidat .tienda-cidat-tabla-inline {display:inline;color:white;}
	.tienda-cidat .mi-carrito-cidat .fila_gastos,
	.tienda-cidat .mi-carrito-cidat .fila_total {padding:0.5em 0;}

	.visualIcon {line-height:1.5em;}

	.actionicon-user-mis_datos {background:url('tienda-cidat/mis-datos.png') no-repeat 0.5em 0.2em !important;}
	.actionicon-user-mis_pedidos {background:url('tienda-cidat/mis-pedidos.png') no-repeat 0.5em 0.2em !important}


	/* -- Tabla Responsive tienda ONLINE CIDAT -- */

	.tienda-cidat table,
	.tienda-cidat tbody {display: block;}

	#content .tienda-cidat table {margin: 0;}

	.tienda-cidat thead { display: none;}
	.tienda-cidat table tr,
	.tienda-cidat table th,
	.tienda-cidat table td {display: block;text-align: left;white-space: normal;}
	.tienda-cidat table td {padding: 0 1em !important;}
	.tienda-cidat table tr {border-bottom: 1px solid #333; padding:0.5em 0;margin-bottom: 0px;}
	.tienda-cidat table th[data-title]:before,
	.tienda-cidat table td[data-title]:before {content: attr(data-title) ":\00A0";font-weight: bold;}
	.tienda-cidat table td:empty {display: none;}
	.tienda-cidat table td:first-child {font-size: 14px;font-weight: bold;margin-bottom: 6px;color: #333;}
	.tienda-cidat table td:first-child:before { content: ''; }

	.tienda-cidat table.empresasTable td, table.empresasTable th{padding:0em 0.5em;}
	.tienda-cidat table.empresasTable td {border:none;}

	.tienda-cidat table td.companyTitle {border-bottom:1px dashed;padding:0.5em;}
	.tienda-cidat table td.companyTitle a{font-size:1.5em;}

	.fila_total td,
	.fila_gastos td {color:white !important;width:auto;}

	#tienda-actions {
		padding:0;
		margin-bottom: 2em;

		li {
			display:block;max-width:100%;margin:0;
			padding-left:0;

			&:last-child {border-bottom:none;}

			a {
				padding:12px 1.1rem 8px;
				line-height:130%;
			}
		}
	}
}